<!--
 * @Description: 上传进度
 * @Author: xiawenlong
 * @Date: 2021-04-28 16:42:51
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-28 16:56:05
-->
<template>
  <div class="course-resources-progress">
    <table-list
      title="视频列表"
      :loading="loading"
      :data="videoList"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '继续添加视频',
    method: _this.handleAdd,
    type: 'primary',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'mobile',
    label: '视频封面',
  },
  {
    prop: 'cardNo',
    label: '视频名称',
  },
  {
    prop: 'deptInfo',
    label: '视频时长',
  },
  {
    prop: 'classInfo',
    label: '上传时间',
  },
  {
    prop: 'registerTime',
    label: '上传进度',
    render: (h, { row }) => {
      return [
        h('el-progress', {
          props: { percentage: row.progress },
        }),
      ]
    },
  },
  {
    prop: 'lastLoginTime',
    label: '状态',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '暂停',
    method: _this.handleAdd,
  },
]
import TableList from '@/components/TableList'
import { students } from '@/api/student'
import to from 'await-to'
export default {
  name: 'CourseResourcesProgress',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      columns,
      operates,
      loading: false,
      videoList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.getVideo()
  },
  methods: {
    async getVideo(queryParams = {}) {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(students({ current, size, ...queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.videoList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.getVideo(queryParams)
    },
    // 新增
    handleAdd() {
      this.$router.push('/course/resources/add')
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getVideo()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getVideo()
    },
  },
}
</script>
<style lang="scss" scoped>
.course-resources-progress {
  ::v-deep.top-bar {
    padding-top: 0;
  }
}
</style>
